import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../images/Plus.svg';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);

`;

export const ModalInfoText = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-500);
width: 100%;
margin-bottom: 12px;
`;


export const ModalStepCounter = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 14px;
line-height: 150%;
color: var(--colors-grey-400);
width: fit-content;
`;


export const NextButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
align-items: center;
background:var(--colors-primary-600);
border-radius: 360px;
padding: 8px 16px;
height: 32px;
width: 67px;
cursor: pointer;

`;




