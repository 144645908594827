import React, { useState } from 'react';
import Modal from '../Modal';

import { ModalTitle } from './OnboardModal.styled';


import OnboardBody from './OnboardBody/OnboardBody';


const OnboardModal = ({ onClose, type, onOpen }) => {

  console.log('type :>> ', type);



  return (
    <Modal onClose={onClose}>

      <OnboardBody onClose={onClose} type={type} onOpen={onOpen} />

    </Modal>
  );
};

export default OnboardModal;
