import React, { useEffect, useState } from 'react';



import { ButtonWrap, CancelButton, ModalInfoText, ModalStepCounter, ModalTitle, NextButton, SaveButton } from './OnboardMessage.styled.js';
import { useDispatch } from 'react-redux';

import { useNavigate, useSearchParams } from 'react-router-dom';
import ModalBoard from '../ModalBoard/index.js';
import { finalOnBoard } from '../../../redux/auth/operations.js';

const ArrayBoardStep = [
  {
    title: 'Керуйте диво-кошиками',
    text: 'На цій сторінці ви можете легко створювати нові диво-кошики, а також редагувати та управляти вже наявними.',
    class: 'board1',
    link: '/statistics?onboard=2',
  },
  {
    title: 'Відстежуйте вашу діяльність',
    text: 'Тут ви можете переглядати дані про продажі, рейтинги та активність ваших диво-кошиків, щоб краще розуміти ефективність вашого магазину.',
    class: 'board2',
    link: '/financial?onboard=3',
  },
  {
    title: 'Керуйте фінансовою інформацією',
    text: 'На цій сторінці ви знайдете звіти про ваші продажі, зможете змінювати платіжну інформацію та налаштовувати на звіти по емейлу.',
    class: 'board3',
    link: '/services?onboard=4',
  },
  {
    title: 'Знайдіть відповіді на ваші запитання',
    text: "Тут ви можете переглянути відповіді на часті запитання або зв'язатись з нашою службою підтримки для отримання допомоги.",
    class: 'board4',
    link: '/settings?onboard=5',
  },
  {
    title: 'Налаштуйте ваш магазин',
    text: 'На цій сторінці ви можете керувати налаштуваннями магазину, емейл сповіщеннями та точками видачі.',
    class: 'board5',
    link: '/main?onboard=6',
  },
  {
    title: 'Створіть диво-кошик',
    text: 'Натисніть на цю кнопку для створення диво-кошиків. Додайте назву, ціну, час отримання, категорію кошику та примітки, щоб залучити більше клієнтів. ',
    class: 'board6',
    link: '/main?onboard=7',
  },
]




const OnboardMessage = ({ onClose, onboard, expires, onOpen, setType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();







  const handleNext = async () => {
    if (parseInt(onboard) < 6)
      navigate(ArrayBoardStep[parseInt(onboard) - 1]?.link)
    else {
      dispatch(finalOnBoard());
      await setType('end');
      console.log('s___________df');
      onOpen();
      onClose();

    }

  };


  return (
    <ModalBoard onClose={onClose} classS={ArrayBoardStep[parseInt(onboard) - 1]?.class} expires={expires}>
      <ModalTitle>{ArrayBoardStep[parseInt(onboard) - 1]?.title}</ModalTitle>
      <ModalInfoText>{ArrayBoardStep[parseInt(onboard) - 1]?.text}</ModalInfoText>



      <ButtonWrap>
        <ModalStepCounter>{onboard}/6</ModalStepCounter>
        <NextButton onClick={handleNext}>Далі</NextButton>

      </ButtonWrap>
    </ModalBoard>
  );
};

export default OnboardMessage;