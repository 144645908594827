import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import { authReducer } from './auth/slice';
import { categoriesApi } from './category/categoriesApi';
import { shopsApi } from './shops/shopsApi';
import { bagsApi } from './bags/bagsApi';
import { calendarApi } from './calendar/calendarApi';
import storage from 'redux-persist/lib/storage';
import { faqApi } from './faq/faqApi';
import { financeApi } from './financial/financeApi';
import { settingsApi } from './settings/settingsApi';
import { documentsApi } from './documents/documentsApi';
import { ordersApi } from './orders/ordersApi';
import { statisticsApi } from './statistics/statisticsApi';
import { notificationApi } from './notification/notificationApi';



const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [shopsApi.reducerPath]: shopsApi.reducer,
    [bagsApi.reducerPath]: bagsApi.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [financeApi.reducerPath]: financeApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,

  },

  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    categoriesApi.middleware,
    shopsApi.middleware,
    bagsApi.middleware,
    calendarApi.middleware,
    faqApi.middleware,
    financeApi.middleware,
    settingsApi.middleware,
    documentsApi.middleware,
    ordersApi.middleware,
    statisticsApi.middleware,
    notificationApi.middleware,
  ],
});

export const persistor = persistStore(store);
