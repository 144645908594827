import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoriesApi = createApi({
  reducerPath: 'categories',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/dictionaries',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: false,
  keepUnusedDataFor: 1,

  endpoints: builder => ({
    fetchCategories: builder.query({
      query: () => '/categories',
    }),
    fetchPrices: builder.query({
      query: () => '/bag-prices',
    }),
    fetchBagStatuses: builder.query({
      query: () => '/bag-statuses',
    }),
    fetchOrderStatuses: builder.query({
      query: () => '/order-statuses',
    }),
    fetchOrganisationTypes: builder.query({
      query: () => '/organization-types',
    }),
  }),
});
export const {
  useFetchCategoriesQuery,
  useFetchPricesQuery,
  useFetchBagStatusesQuery,
  useFetchOrderStatusesQuery,
  useFetchOrganisationTypesQuery
} = categoriesApi;
