import styled from '@emotion/styled';
import { Link } from 'react-router-dom';



export const List = styled.ul`
width: 100%;
display: flex;
align-items:center;
justify-content: center;
  list-style: none;
  padding: 0;
  
  padding: 32px 0;
  gap:16px;

`;

export const ListItem = styled.li`
border-bottom: 1px solid var(--colors-grey-400);
padding: 0px 4px 2px 4px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: var(--font-family);
font-weight: 600;
font-size: 14px;
line-height: 150%;
text-align: center;
color: var(--colors-grey-400);

 
`;