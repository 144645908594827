import { List, ListItem, StyledLink } from "./LegalSection.styled";







const LegalSection = () => {
  return (

    <List>
      <ListItem>
        <StyledLink to="/privacy-policy">Політика конфіденційності</StyledLink>
      </ListItem>
      <ListItem>
        <StyledLink to="/terms-of-use">Умови користування</StyledLink>
      </ListItem>
    </List>

  );
};

export default LegalSection;