import dayjs from "dayjs";
import { format, addMonths, subMonths, parseISO, subDays, isAfter, isBefore, getHours, differenceInMinutes, differenceInHours, differenceInDays, isValid } from 'date-fns';
import { parse } from 'date-fns/parse';

import { uk } from 'date-fns/locale';
import { useLocation, useNavigate } from "react-router-dom";
const today = dayjs();
export function isValidISODate(dateString) {
    // Перевірка чи рядок є коректним ISO 8601 форматом
    return dayjs(dateString, dayjs.ISO_8601, true).isValid();
}

export function returnStatus(params) {
    switch (params) {
        case 'draft':
            return {
                statusName: 'Створено',
                statusBtn: 'Виставити на продаж',
                statusCol: 'var(--colors-grey-700)',
                statusBtnCol: 'var(--colors-secondary-500)',
            }
        case 'active':
            return {
                statusName: 'Продається',
                statusBtn: 'Зняти з продажу',
                statusCol: 'var(--colors-secondary-600);',
                statusBtnCol: 'var(--colors-error-600)',
            }

        default:
            break;
    }

}


export const formatRepeat = (typeRepeat) => {
    switch (typeRepeat) {
        case 'daily':
            return 'Щоденно';
        case 'weekly':
            return 'Щотижнево';
        case 'monthly':
            return 'Щомісячно';
        default:
            break;
    }

}




export const formatDate = (date) => {

    return dayjs(date).format('DD.MM.YYYY');
};

export const formatDateTimeRange = (datetime_from, datetime_to, schedule = false) => {
    const now = dayjs();
    const fromDate = dayjs(datetime_from);
    const toDate = dayjs(datetime_to);

    // Перевірка чи це сьогодні
    const isToday = fromDate.isSame(now, 'day');
    const isTomorrow = fromDate.isSame(now.add(1, 'day'), 'day');

    // Визначення дня
    const dayText = isToday ? 'Сьогодні' : isTomorrow ? 'Завтра' : fromDate.format('DD.MM.YYYY');

    // Форматування часу
    const fromTime = fromDate.format('HH:mm');
    const toTime = toDate.format('HH:mm');

    return schedule ? `${fromTime}–${toTime}` : `${dayText} ${fromTime}–${toTime}`;
};

export const formatedRepeat = (schedule) => {

    const daysMapping = {
        mo: 'Пн',
        tu: 'Вт',
        we: 'Ср',
        th: 'Чт',
        fr: 'Пт',
        sa: 'Сб',
        su: 'Нд'
    };


    switch (schedule?.period) {
        case 'daily':
            return schedule?.day_repeat === 2 ? 'Через день' : 'Кожного дня'
        case 'weekly':
            return Object.keys(daysMapping)
                .filter(day => schedule[day])
                .map(day => daysMapping[day])
                .join(', ');
        case 'monthly':
            return `${schedule?.day_of_month} числа місяця`




        default:
            break;
    }
};


export const dayClassName = (date) => {
    if (date < today - 1) {
        return "react-datepicker__day--min-date";
    }
    return null;
};


export const createDateWithTime = (hours, minutes = 0) => {
    return dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
};

function capitalizeFirstLetter(str) {
    if (typeof str !== 'string' || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export function formatMonth(dateString) {
    // Перетворюємо рядок у формат дати
    const date = parse(dateString, 'yyyy-MM', new Date(), { locale: uk });

    // Форматуємо дату у потрібний формат
    return capitalizeFirstLetter(format(date, 'LLLL yyyy', { locale: uk }));
}

export function formatDay(dateString) {
    try {
        // Перетворюємо рядок у формат дати (день-місяць-рік)
        const date = parse(dateString, 'yyyy-MM-dd', new Date(), { locale: uk });

        // Перевіряємо, чи дійсно це дата
        if (isNaN(date)) {
            return 'Невірний формат';
        }

        // Форматуємо дату у потрібний формат
        return format(date, 'd MMMM', { locale: uk });
    } catch (error) {
        return 'Невірний формат';
    }
}

// Функція для отримання попереднього місяця
export function getPreviousMonth(dateString) {
    try {
        const date = parse(dateString, 'yyyy-MM', new Date(), { locale: uk });
        if (isNaN(date)) return 'Невірний формат';
        const previousMonth = subMonths(date, 1);
        return format(previousMonth, 'yyyy-MM');
    } catch (error) {
        return 'Невірний формат';
    }
}



// Функція для отримання наступного місяця
export function getNextMonth(dateString) {
    try {
        const date = parse(dateString, 'yyyy-MM', new Date(), { locale: uk });
        if (isNaN(date)) return 'Невірний формат';
        const nextMonth = addMonths(date, 1);
        return format(nextMonth, 'yyyy-MM');
    } catch (error) {
        return 'Невірний формат';
    }
}
// export function transformData(data) {
//     return Object.keys(data).map(date => ({
//         date: date,
//         dateF: new Date(date),
//         bags: data[date],

//     }));
// }
export function transformData(data) {
    console.log('data :>> ', data);
    return Object.keys(data).map(date => {
        const dayData = data[date] || {};
        const { is_working, day, ...bags } = dayData;
        return {
            date: date,
            dateF: new Date(date),
            isWorking: is_working,
            day: day,
            bags: Object.values(bags),  // Масив усіх кошиків для дати
        };
    });
}



export function firstDayMonth(data) {
    return parse(data, 'yyyy-MM', new Date());
}

export const BagWords = (num) => {
    if (num === 1) {
        return `${num} кошик`;
    } else if (num >= 2 && num <= 4) {
        return `${num} кошика`;
    } else {
        return `${num} кошиків`;
    }
};

export const formatTimeRange = (datetime_from, datetime_to) => {
    const from = parseISO(datetime_from);
    const to = parseISO(datetime_to);

    const formattedFrom = format(from, 'HH:mm');
    const formattedTo = format(to, 'HH:mm');

    return `${formattedFrom}–${formattedTo}`;
};
export const daysOfWeek = {
    mo: 'Пн',
    tu: 'Вт',
    we: 'Ср',
    th: 'Чт',
    fr: 'Пт',
    sa: 'Сб',
    su: 'Нд'
}

export const daysOfWeek1 = {
    mo: 'Понеділок',
    tu: 'Вівторок',
    we: 'Середа',
    th: 'Четвер',
    fr: 'Пʼятниця',
    sa: 'Субота',
    su: 'Неділя'
}

export function addTimeToDate(startDate, firstTime) {
    const newStartDate = dayjs(startDate);

    if (dayjs.isDayjs(firstTime)) {
        return newStartDate
            .hour(firstTime.hour())
            .minute(firstTime.minute())
            .second(firstTime.second())
            .millisecond(firstTime.millisecond());
    } else if (firstTime instanceof Date) {
        return newStartDate
            .hour(firstTime.getHours())
            .minute(firstTime.getMinutes())
            .second(firstTime.getSeconds())
            .millisecond(firstTime.getMilliseconds());
    } else {
        throw new Error('Invalid type for firstTime');
    }
}


export const determineBagParameters = (bag) => {
    const now = new Date();
    const datetimeFrom = parseISO(bag.datetime_from);
    const datetimeTo = parseISO(bag.datetime_to);

    let status = "";
    let bgCol = "";
    let backCol = "";
    let WordBags = "";
    let opc = "1";
    let statusBtn = ""
    let statusBtnCol = ""
    let deleteWidth = "calc(100% - 16px) / 2"
    let reservedWord = `Заброньовано: ${bag?.reserved_count}`
    let quantyHide = false;
    let funct = '';
    let editBtnHide = false;
    let btnWrapHide = false;
    let pointBtnHide = false;
    let allBtnHide = false;
    // Перевірка для статусу "draft"
    if (bag.status === "draft" || bag.status === "scheduling") {
        status = "Створено";
        bgCol = "var(--colors-grey-700)";
        backCol = "var(--colors-grey-25)";
        WordBags = `Буде ${bag.count} кошиків`;
        statusBtn = "Виставити на продаж";
        statusBtnCol = "var(--colors-secondary-500)";
        reservedWord = ``;
        funct = 'publish';
    }
    // Перевірка для статусу "active" з зарезервованими місцями менше ніж загальна кількість
    else if (bag.status === "active") {



        status = "Продається";
        bgCol = "var(--colors-secondary-600)";
        backCol = "var(--colors-secondary-25)";
        WordBags = `Заброньовано ${bag.reserved_count} з ${bag.count} кошиків`;
        statusBtn = "Зняти з продажу";
        statusBtnCol = "var(--colors-error-600)";
        if (bag.reserved_count > 0) {
            deleteWidth = "100%"
            editBtnHide = true;
            pointBtnHide = true;
        } else { funct = 'unpublish' }

    }
    // Перевірка для статусу "active" з зарезервованими місцями рівно кількості

    // Перевірка для статусу "active" у часовому проміжку між datetime_from та datetime_to
    else if (bag.status === "issuing") {
        status = "Очікує на отримувачів";
        bgCol = "var(--colors-warning-400)";
        backCol = "var(--colors-warning-25)";
        deleteWidth = "100%"
        WordBags = bag.reserved_count === 0 ? `Заброньовано ${bag.reserved_count} з ${bag.count} кошиків` : `Забрали ${bag.done_count} з ${bag.reserved_count} кошиків`;
        reservedWord = `Забрали ${bag?.done_count}`;
        editBtnHide = true;
        pointBtnHide = true;
    }
    else if (bag.status === "unsuccessful") {
        status = "Не продано";
        bgCol = "var(--colors-error-400)";
        backCol = "var(--colors-error-50)";
        WordBags = `Залишилось ${bag.count - bag.done_count} з ${bag.count} кошиків`;
        statusBtn = "Повторити кошик";
        statusBtnCol = "var(--colors-primary-600)";
        reservedWord = `Залишилось ${bag?.count - bag?.done_count} з ${bag?.count} кошиків`;
        quantyHide = true;
        funct = 'repeat'
        btnWrapHide = true;
        allBtnHide = true;
        pointBtnHide = true;
    }
    else if (bag.status === "outofstock") {
        status = "Розпродано";
        bgCol = "var(--colors-success-700)";
        backCol = "var(--colors-success-50)";
        WordBags = `Продано ${bag.done_count} з ${bag.count} кошиків`;
        statusBtn = "Повторити кошик";
        statusBtnCol = "var(--colors-primary-600)";
        quantyHide = true;
        funct = 'repeat'
        btnWrapHide = true;
        allBtnHide = true;
        pointBtnHide = true;
    }
    else if (bag.status === "cancelled") {
        status = "Видалено";
        bgCol = "var(--colors-grey-400)";
        backCol = "var(--colors-grey-50)";
        WordBags = `Було в наявності ${bag.count} кошиків`;
        opc = "0.7"
        statusBtn = "Повторити кошик";
        statusBtnCol = "var(--colors-primary-600)";
        const count = parseInt(bag?.count);
        const lastDigit = count % 10;
        const lastTwoDigits = count % 100;
        reservedWord = (lastTwoDigits >= 11 && lastTwoDigits <= 14)
            ? `${count} кошиків`
            : (lastDigit === 1)
                ? `${count} кошик`
                : (lastDigit >= 2 && lastDigit <= 4)
                    ? `${count} кошики`
                    : `${count} кошиків`;
        quantyHide = true;
        funct = 'repeat'
        btnWrapHide = true;
        allBtnHide = true;
        pointBtnHide = true;
    }
    else if (bag.status === "booked") {

        status = "Все заброньовано";
        bgCol = "var(--colors-primary-600)";
        backCol = "var(--colors-primary-25)";
        WordBags = `Заброньовано ${bag.reserved_count} з ${bag.count} кошиків`;
        deleteWidth = "100%"
        editBtnHide = true;
        pointBtnHide = true;
    }


    return { status, bgCol, backCol, WordBags, opc, statusBtn, statusBtnCol, deleteWidth, reservedWord, quantyHide, funct, editBtnHide, btnWrapHide, pointBtnHide, allBtnHide };
};

export const getInitial = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase();
};

export const getFormatCharts = (rawData, type) => {
    if (!rawData) return { values: [], labels: [] }
    if (type === "months") {
        const monthNames = {
            "01": "Січень",
            "02": "Лютий",
            "03": "Березень",
            "04": "Квітень",
            "05": "Травень",
            "06": "Червень",
            "07": "Липень",
            "08": "Серпень",
            "09": "Вересень",
            "10": "Жовтень",
            "11": "Листопад",
            "12": "Грудень",
        };

        const labels = Object.keys(rawData).map(key => {
            const [year, month] = key.split("-");
            return `${monthNames[month]}`;
        });

        const values = Object.values(rawData).map(value => value[0]);
        return { values, labels }
    }
    else if (type === "days") {
        const labels = Object.keys(rawData).map(key => {
            const [year, month, day] = key.split("-");
            return `${day}.${month}`;
        });

        const values = Object.values(rawData).map(value => value[0]);
        return { values, labels }
    } else {
        const labels = Object.keys(rawData).map(key => {

            return `${key}`;
        });

        const values = Object.values(rawData).map(value => value[0]);
        return { values, labels }
    }
};

export const formatTimeDate = (dateString) => {
    // Перевірка і очистка зайвої крапки перед часовою зоною
    let cleanedDateString = String(dateString);

    // Якщо формат містить зайву крапку перед +00:00, замінюємо її
    if (/\.\+00:00$/.test(cleanedDateString)) {
        cleanedDateString = cleanedDateString.replace(/\.\+00:00$/, '+00:00');
    }

    // Створюємо об'єкт дати після очищення
    const date = new Date(cleanedDateString);
    const now = new Date();



    // Перевіряємо валідність дати
    if (isNaN(date)) {
        console.error('Невірна дата:', cleanedDateString);
        return 'Невідома дата';
    }

    const minutesDiff = differenceInMinutes(now, date);
    const hoursDiff = differenceInHours(now, date);
    const daysDiff = differenceInDays(now, date);

    // Якщо менше години - вивести кількість хвилин назад
    if (minutesDiff < 60) {
        return `${minutesDiff} хвилин назад`;
    }

    // Якщо більше години, але в межах сьогоднішнього дня - вивести тільки годину і хвилини
    if (hoursDiff < 24 && daysDiff === 0) {
        return format(date, 'HH:mm');
    }

    // Якщо дата вже вчора і далі - формат DD.MM.YYYY HH:MM
    return format(date, 'dd.MM.yyyy HH:mm');
};