import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const faqApi = createApi({
  reducerPath: 'faq',

  baseQuery: fetchBaseQuery({
    baseUrl: 'https://test-backend.stravaporuch.com.ua/api/v1/',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),

  tagTypes: ['Faq'],

  endpoints: builder => ({
    getFaq: builder.query({
      query: () => ({
        url: `/faq`,
        method: 'GET',
      }),
      providesTags: ['Faq'],
    }),

    createFaq: builder.mutation({
      query: data => ({
        url: '/merchant-feedbacks/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Faq'],
    }),

  }),
});

export const {
  useGetFaqQuery,
  useCreateFaqMutation
} = faqApi;
