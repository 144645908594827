import React, { useEffect, useState, forwardRef } from 'react';
import Modal from '../Modal';

import {
  IconSbag,
  IconSend,
  ModalHeadBox,
  ModalHeadBtn,
  ModalTitle,
  NotifyModalInfoBox,
  NotifyModalItem,
  NotifyModalList,
  NotifyModalText,
  NotifyModalTime,
  NotifyModalTitle
} from './NotificationModal.styled';

import { useReadNotificationsMutation } from '../../../redux/notification/notificationApi';
import { formatTimeDate } from 'utils/function';
import ModalMessage from '../ModalMessage';
import { useNavigate } from 'react-router-dom';
import ImageAnimation2 from 'components/layout/loader/ImageAnimation2';

const NotificationModal = forwardRef(({ onClose, notificationsAr, setNotificationsAr, markUnread }, ref) => {
  const navigate = useNavigate();
  const [readNotifications, { isLoading: isLoadingMes }] = useReadNotificationsMutation();
  console.log('object :>> ', notificationsAr);

  const handleRead = async () => {
    if (markUnread) return;
    const ids = notificationsAr.filter(item => !item.read).map(item => item.id).join(',');
    console.log('ids :>> ', ids);
    try {
      await readNotifications(ids).unwrap();
      console.log('Details successfully');
      const updatedNotifications = notificationsAr.map(item =>
        item.read ? item : { ...item, read: true }
      );
      setNotificationsAr(updatedNotifications);
    } catch (error) {
      console.error('Failed to Details:', error);
    }
  };

  const handleReadById = async (id) => {
    try {
      await readNotifications(`${id}`).unwrap();
      console.log('Details successfully');
      const updatedNotifications = notificationsAr.map(item =>
        item.id === id ? { ...item, read: true } : item
      );
      setNotificationsAr(updatedNotifications);
      navigate(`/main/viewbag/${id}`);
    } catch (error) {
      console.error('Failed to Details:', error);
    }
    onClose();
  };

  return (
    <ModalMessage pad={"false"} onClose={onClose}>
      <ModalHeadBox>
        <ModalTitle>Повідомлення</ModalTitle>
        {isLoadingMes ? <ImageAnimation2 /> : <ModalHeadBtn onClick={handleRead}><IconSend /> Прочитати всі</ModalHeadBtn>}
      </ModalHeadBox>
      <NotifyModalList>
        {notificationsAr.map((notify) =>
          <NotifyModalItem key={notify.id} onClick={() => handleReadById(notify.id)} read={notify.read ? "true" : "false"}>
            <IconSbag />
            <NotifyModalInfoBox>
              <NotifyModalTitle>{notify.title}</NotifyModalTitle>
              <NotifyModalText>{notify.message}</NotifyModalText>
              <NotifyModalTime>{formatTimeDate(notify.createdAt)}</NotifyModalTime>
            </NotifyModalInfoBox>
          </NotifyModalItem>
        )}
      </NotifyModalList>
    </ModalMessage>
  );
});

export default NotificationModal;
