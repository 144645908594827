import { createPortal } from 'react-dom';
import {

  ModalContent,
  ModalOverlay,
  CloseBtn,
  ModalOverlayLeft,
  ModalOverlayRight,
} from './ModalBoard.styled';

const modalRoot = document.querySelector('#root-module');

const ModalBoard = ({ children, onClose, classS, expires }) => {


  const handleContentClick = event => {
    event.stopPropagation();
  };

  return createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalOverlayLeft />
      <ModalOverlayRight style={{ justifyContent: classS === 'board6' ? 'end' : "start" }}>
        <ModalContent onClick={handleContentClick} className={classS} expires={expires ? 'true' : ''} >

          <CloseBtn onClick={onClose} />

          {children}
        </ModalContent>
      </ModalOverlayRight>
    </ModalOverlay>,
    modalRoot
  );
};

export default ModalBoard;
